import {
  AUTH_LOGOUT,
  FETCH_AUTH,
  FETCH_REGISTER,
  GET_IS_AUTH,
  GET_IS_AUTH_LOADING,
  GET_ROLE,
  SET_DRAWERS_CLEAN,
  SET_IS_AUTH_LOADING,
  SET_LOGIN_PARAMS,
  SET_LOGOUT,
  SET_TERMINAL,
  SET_TERMINALS,
} from '@/store/actions'
import { SET_CULTURES, SET_EXPORTERS_ALL } from '@/views/control/store/actions'
import { SET_PROFILE } from '@/views/profile/store/actions'
import { SET_QUOTAS_LIST } from '@/views/home/store/actions'
import { SET_SUPPLIERS_ALL } from '@/views/exporter/store/actions'
import {
  getLoginToken,
  getUserRole,
  removeLoginParams,
  setLoginParams,
} from '@/core'
import API from '@/api'
import router from '@/router'

const token = getLoginToken()
const role = getUserRole()

export default {
  state: {
    token: token || '',
    role: role || '',
    isLoading: false,
  },
  getters: {
    [GET_IS_AUTH]: state => Boolean(state.token),
    [GET_ROLE]: state => state.role,
    [GET_IS_AUTH_LOADING]: state => state.isLoading,
  },
  actions: {
    [FETCH_AUTH]: ({ commit }, { data, isPhone }) => {
      commit(SET_IS_AUTH_LOADING, true)
      const method = isPhone ? API.auth.login : API.auth.auth

      method(data)
        .then(({ data }) => {
          const { token, role, default_unload, profile } = data

          setLoginParams(token, role, default_unload, profile)
          commit(SET_TERMINAL, default_unload, { root: true })
          commit(SET_PROFILE, profile)
          commit(SET_LOGIN_PARAMS, [token, role])
          commit(SET_DRAWERS_CLEAN, null, { root: true })
          router.push({ name: 'Home' })
        })
        .catch(error => console.log('error', error))
        .finally(() => commit(SET_IS_AUTH_LOADING, false))
    },
    [FETCH_REGISTER]: async ({ commit }, user) => {
      commit(SET_IS_AUTH_LOADING, true)
      try {
        const { data } = await API.auth.register(user)
        const { token, role, default_unload, profile } = data

        setLoginParams(token, role, default_unload, profile)
        commit(SET_TERMINAL, default_unload, { root: true })
        commit(SET_PROFILE, profile)
        commit(SET_LOGIN_PARAMS, [token, role])
        commit(SET_DRAWERS_CLEAN, null, { root: true })

        await router.push({ name: 'Home' })
      } catch (error) {
        return { is_confirmed: false, error }
      } finally {
        commit(SET_IS_AUTH_LOADING, false)
      }
    },
    [AUTH_LOGOUT]: ({ commit }) => {
      removeLoginParams()
      commit(SET_LOGOUT)
      commit(SET_TERMINAL, null, { root: true })
      commit(SET_TERMINALS, [])
      commit(SET_QUOTAS_LIST, { quotas: [] })
      commit(SET_CULTURES, [])
      commit(SET_EXPORTERS_ALL, [])
      commit(SET_PROFILE, {})
      commit(SET_SUPPLIERS_ALL, [])
      router.push({ name: 'Login' })
    },
  },
  mutations: {
    [SET_LOGIN_PARAMS]: (state, [token, role]) => {
      state.token = token
      state.role = role
    },
    [SET_LOGOUT]: state => {
      state.token = ''
      state.role = ''
    },
    [SET_IS_AUTH_LOADING]: (state, payload) => {
      state.isLoading = payload
    },
  },
}
