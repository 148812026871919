import { GET_DIALOG, GET_ROLE, SET_DIALOG } from '@/store/actions'
import { errHandlerHelper } from '@/core/errorHandler'
import { mapGetters, mapMutations } from 'vuex'

export default {
  data() {
    return {
      windowWidth: document.getElementById('app').getBoundingClientRect().width,
    }
  },
  computed: {
    ...mapGetters({ getDialog: GET_DIALOG, currentRole: GET_ROLE }),
    isSuperAdmin() {
      return this.currentRole === 'root'
    },
    isSupport() {
      return this.currentRole === 'support'
    },
    isViewer() {
      return this.currentRole === 'viewer'
    },
    isAdmin() {
      return this.currentRole === 'admin'
    },
    isManager() {
      return this.currentRole === 'manager'
    },
    isSupervisor() {
      return this.currentRole === 'supervisor'
    },
    isExporter() {
      return this.currentRole === 'exporter'
    },
    isParkman() {
      return this.currentRole === 'parkman'
    },
    isSmallMobile() {
      return this.windowWidth <= 550
    },
    isMobile() {
      return this.windowWidth <= 768
    },
    isTablet() {
      return this.windowWidth <= 1024
    },
    getParentPageRoute() {
      const { path, name } = this.$route.matched[0]
      let key

      if (path === '' && name === 'Home') {
        key = '/'
      } else {
        key = path
      }

      return key
    },
  },
  mounted() {
    this.$nextTick(() => window.addEventListener('resize', this.handleResize))
  },
  methods: {
    ...mapMutations({ setDialog: SET_DIALOG }),
    errHandler(err) {
      errHandlerHelper(err)
    },
    handleResize() {
      this.windowWidth = document
        .getElementById('app')
        ?.getBoundingClientRect().width
    },
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize)
  },
}
