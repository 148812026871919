export const onlyTruckNumValidator = numAuto =>
  !/^[a-zA-zА-Яа-я]{1}[0-9]{3}[a-zA-zА-Яа-я]{2}[0-9]{2,3}$/.test(numAuto) &&
  !/^[0-9]{4}[a-zA-zА-Яа-я]{3}$/.test(numAuto) &&
  !/^[a-zA-zА-Яа-я]{1}[0-9]{4}[a-zA-zА-Яа-я]{2}$/.test(numAuto) &&
  // белорусский тягач
  !/^[a-zA-zА-Яа-я]{2}[0-9]{4}-[1-7]$/.test(numAuto) &&
  // казахские тягачи
  !/^[0-9]{3}[a-zA-zА-Яа-я]{3}[0-9]{2}$/.test(numAuto) &&
  !/^[0-9]{3}[a-zA-zА-Яа-я]{2}[0-9]{2}$/.test(numAuto) &&
  !/^[a-zA-zА-Яа-я][0-9]{6}$/.test(numAuto)

export const numTrailerValidator = numAuto =>
  !/^[a-zA-zА-Яа-я]{2}[0-9]{6}$/.test(numAuto) &&
  // белорусский прицеп
  !/^[a-zA-zА-Яа-я][0-9]{4}[a-zA-zА-Яа-я]-[1-7]$/.test(numAuto) &&
  // казахский прицеп
  !/^[a-zA-zА-Яа-я]{3}[0-9]{4}$/.test(numAuto)

export const rulesNumAuto = {
  plate_truck: [
    {
      required: true,
      message: 'Заполните номер авто',
      trigger: 'submit',
    },
    {
      required: true,
      validator: (rule, value, callback) => {
        onlyTruckNumValidator(value) ? callback(new Error()) : callback()
      },
      message:
        'Формат номера должен быть "м213тт99" или "1212ттт" или "e2233кк"',
      trigger: 'submit',
    },
  ],
}

export const rulesPhone = {
  phone: [
    {
      required: true,
      message: 'Введите Ваш номер телефона',
      trigger: ['blur', 'change'],
    },
    {
      required: true,
      min: 16,
      message: 'Номер телефона не полный',
      trigger: ['blur', 'change'],
    },
  ],
}

export const rulesSms = {
  smsCode: [
    {
      required: true,
      message: 'Введите код из СМС',
      trigger: ['blur', 'change'],
    },
    {
      required: true,
      min: 7,
      message: 'Код из СМС не полный',
      trigger: ['blur', 'change'],
    },
  ],
}
