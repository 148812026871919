export const parkmanTableFields = [
  {
    key: 0,
    prop: 'plate_truck',
    label: 'Номер авто',
    command: 'commandDefault',
    width: '150',
  },
  {
    key: 1,
    prop: 'truck_type',
    label: 'Тип авто',
    command: 'commandDefault',
    width: '180',
  },
  {
    key: 2,
    prop: 'buffer_time',
    label: 'Заезд в буфер',
    command: 'commandTime',
    width: '160',
  },
  {
    key: 3,
    prop: 'phone',
    label: 'Телефон',
    command: 'commandPhone',
    width: '180',
  },
  {
    key: 4,
    prop: 'name',
    label: 'ФИО водителя',
    command: 'commandDefault',
    width: '180',
  },
  {
    key: 5,
    prop: 'culture',
    label: 'Культура',
    command: 'commandDefault',
    width: '180',
  },
  {
    key: 6,
    prop: 'exporter_name',
    label: 'Экспортер',
    command: 'commandDefault',
    width: '150',
  },
  {
    key: 7,
    prop: 'supplier_name',
    label: 'Поставщик',
    command: 'commandDefault',
    width: '150',
  },
  {
    key: 8,
    prop: 'common_supplier_name',
    label: 'Поставщик по ИНН',
    command: 'commandDefault',
    width: '150',
  },
  {
    key: 9,
    prop: 'parking_time',
    label: 'Прибыл на парковку',
    command: 'commandParking',
    width: '160',
  },
]

export const defaultParkmanFields = [0, 2, 3]
