import * as actionsCommon from '@/store/actions'
import {
  FETCH_DETACH_REASONS,
  FETCH_TIMESLOTS,
  GET_IS_LOADING_CALL_DRIVER,
  GET_IS_LOADING_TIMESLOTS,
  GET_PARKMAN_DETACH_REASONS,
  GET_PARKMAN_TABLE_FIELDS,
  GET_TIMESLOTS_FROM_STATE,
  SAVE_PARKMAN_TABLE_FIELDS,
  SET_IS_LOADING_CALL_DRIVER,
  SET_IS_LOADING_TIMESLOTS,
  SET_PARKMAN_DETACH_REASONS,
  SET_PARKMAN_TABLE_FIELDS,
  SET_TIMESLOTS,
  TO_STATUS_ARRIVED,
  TO_STATUS_DETACH,
} from '@/views/parkman/store/actions'
import { defaultParkmanFields } from '@/views/parkman/data/constants'
import { getParkmanTableColumns, setParkmanTableColumns } from '@/core'
import API from '@/api'

export default {
  state: {
    timeslots: [],
    isLoading: true,
    isLoadingCallDriver: false,
    parkmanTableColumns: getParkmanTableColumns() || defaultParkmanFields,
    detachReasons: [],
  },
  getters: {
    [GET_TIMESLOTS_FROM_STATE]: state => state.timeslots,
    [GET_IS_LOADING_TIMESLOTS]: state => state.isLoading,
    [GET_IS_LOADING_CALL_DRIVER]: state => state.isLoadingCallDriver,
    [GET_PARKMAN_TABLE_FIELDS]: state => state.parkmanTableColumns,
    [GET_PARKMAN_DETACH_REASONS]: state => state.detachReasons,
  },
  actions: {
    [FETCH_TIMESLOTS]({ commit, rootGetters }) {
      commit(SET_IS_LOADING_TIMESLOTS, true)
      const unload_id =
        rootGetters[actionsCommon.GET_TERMINAL_CURRENT_ID] || null

      API.timeslots
        .getBuffer({ unload_id, 'per-page': 50 })
        .then(({ data }) => commit(SET_TIMESLOTS, data))
        .catch(error => console.log('error', error))
        .finally(() => commit(SET_IS_LOADING_TIMESLOTS, false))
    },

    [FETCH_DETACH_REASONS]({ commit }) {
      commit(SET_IS_LOADING_TIMESLOTS, true)

      API.timeslots
        .getParkmanDetachReasons()
        .then(({ data }) => commit(SET_PARKMAN_DETACH_REASONS, data))
        .catch(error => console.log('error', error))
        .finally(() => commit(SET_IS_LOADING_TIMESLOTS, false))
    },

    [TO_STATUS_ARRIVED]({ commit, dispatch }, timeslot_id) {
      commit(SET_IS_LOADING_CALL_DRIVER, true)

      API.timeslots
        .toStatusArrived({ timeslot_id })
        .then(() => dispatch(FETCH_TIMESLOTS))
        .catch(error => console.log('error', error))
        .finally(() => commit(SET_IS_LOADING_CALL_DRIVER, false))
    },
    [TO_STATUS_DETACH]({ commit, dispatch }, payload) {
      commit(SET_IS_LOADING_CALL_DRIVER, true)

      return API.timeslots
        .setDetachTimeslot(payload)
        .then(() => dispatch(FETCH_TIMESLOTS))
        .catch(error => console.log('error', error))
        .finally(() => commit(SET_IS_LOADING_CALL_DRIVER, false))
    },
    [SAVE_PARKMAN_TABLE_FIELDS]({ commit }, payload) {
      setParkmanTableColumns(payload)
      commit(SET_PARKMAN_TABLE_FIELDS, payload)
    },
  },
  mutations: {
    [SET_TIMESLOTS](state, payload) {
      state.timeslots = payload
    },
    [SET_IS_LOADING_TIMESLOTS](state, payload) {
      state.isLoading = payload
    },
    [SET_IS_LOADING_CALL_DRIVER](state, payload) {
      state.isLoadingCallDriver = payload
    },
    [SET_PARKMAN_TABLE_FIELDS](state, payload) {
      state.parkmanTableColumns = payload
    },
    [SET_PARKMAN_DETACH_REASONS](state, payload) {
      state.detachReasons = payload
    },
  },
}
