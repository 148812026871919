export const GET_TIMESLOTS_FROM_STATE =
  '[Parkman/getter] GET_TIMESLOTS_FROM_STATE'
export const GET_IS_LOADING_TIMESLOTS =
  '[Parkman/getter] GET_IS_LOADING_TIMESLOTS'
export const GET_IS_LOADING_CALL_DRIVER =
  '[Parkman/getter] GET_IS_LOADING_CALL_DRIVER'
export const GET_PARKMAN_TABLE_FIELDS =
  '[Parkman/getter] GET_PARKMAN_TABLE_FIELDS'
export const GET_PARKMAN_DETACH_REASONS =
  '[Parkman/getter] GET_PARKMAN_DETACH_REASONS'

export const FETCH_TIMESLOTS = '[Parkman/action] FETCH_TIMESLOTS'
export const FETCH_DETACH_REASONS = '[Parkman/action] FETCH_DETACH_REASONS'
export const TO_STATUS_ARRIVED = '[Parkman/action] TO_STATUS_ARRIVED'

export const TO_STATUS_DETACH = '[Parkman/action] TO_STATUS_DETACH'

export const SAVE_PARKMAN_TABLE_FIELDS =
  '[Parkman/action] SAVE_PARKMAN_TABLE_FIELDS'

export const SET_IS_LOADING_TIMESLOTS =
  '[Parkman/mutation] SET_IS_LOADING_TIMESLOTS'
export const SET_IS_LOADING_CALL_DRIVER =
  '[Parkman/mutation] SET_IS_LOADING_CALL_DRIVER'
export const SET_TIMESLOTS = '[Parkman/mutation] SET_TIMESLOTS'
export const SET_PARKMAN_TABLE_FIELDS =
  '[Parkman/mutation] SET_PARKMAN_TABLE_FIELDS'
export const SET_PARKMAN_DETACH_REASONS =
  '[Parkman/mutation] SET_PARKMAN_DETACH_REASONS'
